import Image from "next/image";

import splitImg from "../../public/images/split/herooo.png";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    {/* <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      
                    </h4> */}
                    <p
                      className="description text-center"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      <span className="w-600">
                        JpegWriter analyzes your photos,
                      </span>{" "}
                      <br /> understands the story behind them, and converts
                      them into engaging, SEO-optimized captions, Tags, blog
                      posts, and social media updates.
                    </p>
                    {/* <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <li>- Craft Articles in Under 20 Seconds.</li>
                      <li>- Reclaim Hundreds of Valuable Hours with AI</li>
                      <li>- Elevate Copywriting with Rewriter.</li>
                    </ul> */}
                    <p
                      className="description w-600 text-center px-5"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      All content is created in your unique voice, ensuring
                      consistency and quality across all platforms,
                      effortlessly.
                    </p>
                    <p
                      className="description w-600 text-center px-5"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Our intelligent Co-Pilot makes it possible to handle a
                      month&apos;s worth of posts, captions, and articles in 15
                      minutes.
                    </p>
                    <div
                      className="view-more-button d-flex justify-content-center mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <Link className="btn-default" href="/contact">
                        Instantly Test for Free!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;
